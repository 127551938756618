<div class="main-wrap ">

    <!-- <div class="help">
        <img src="assets/images/Icon material-help-outline.png"><span>คำแนะนำ</span>
    </div> -->
    <div class="row">
        
        <div class="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style="background-image: url(assets/images/theme6.png)">
            <div class="web-content">

                <div class="logo">
                    <img src="assets/images/aimc_logo.png">
                </div>
                <h4>
                    ระบบอบรมออนไลน์
                </h4>
                <h4>
                    LMS Expert E-LEARNING
                </h4>
            </div>
            <!-- <img class="bg1" src="../../assets/images/bg1.png"> -->
        </div>

        <div class="col-xl-6 vh-lg-100 align-items-center d-flex rounded-lg overflow-hidden bg-box-form">
            <div class="back-btn" routerLink="/login">
                <img src="../../assets/images/Icon awesome-arrow-circle-left.png" alt=""> &nbsp;&nbsp;&nbsp; <span style="font-size: 18px;">กลับหน้าล็อกอิน</span>
            </div>
            <div class="card shadow-none border-0 ml-auto mr-auto login-card">
                <div class="card-body rounded-0 text-left">
                    <div class="logo_mobile">
                        <img src="assets/images/aimc_logo.png">
                    </div>
                    <h2 class="display2-md-size mb-3 font-bold">ตั้งรหัสผ่านใหม่</h2>
                    <form [formGroup]="Login">
                        <div class="form-group icon-input mb-3">
                            <img class='user' src="../../assets/images/Icon awesome-lock.png">
                            <div class="line"></div>
                            <input formControlName="password" type="password"
                                class="style2-input pl-6 form-control text-grey-900 font-xsss fw-600"
                                placeholder="รหัสผ่านใหม่" />
                            <div class="mt-1 text-danger" *ngIf="Login.get('password').hasError('required')&&Login.get('password').touched">
                                จำเป็นต้องป้อนข้อมูล
                            </div>
                            <div class="mt-1 text-danger"
                                *ngIf="(!Login.get('password').hasError('required'))&&Login.value.password.length<6">
                                ระบุอย่างน้อย 6 ตัวอักษร
                            </div>
                        </div>
                        <div class="form-group icon-input mb-3">
                            <img class='user' src="../../assets/images/Icon awesome-lock.png">
                            <div class="line"></div>
                            <input formControlName="confirmpass" type="password"
                                class="style2-input pl-6 form-control text-grey-900 font-xsss fw-600"
                                placeholder="ยืนยันรหัสผ่าน" />
                                <div class="mt-1 text-danger" *ngIf="Login.get('confirmpass').hasError('required')&&Login.get('confirmpass').touched">
                                    จำเป็นต้องป้อนข้อมูล
                                </div>
                            <div class="mt-1 text-danger" *ngIf="Login.value.password!=Login.value.confirmpass">
                                รหัสผ่านไม่ตรงกัน
                            </div>
                        </div>
                    </form>
                    <div class="col-sm-12 p-0 text-left">
                        <div class="form-group mb-1">
                            <button (click)="confirm()"
                                [disabled]="Login.invalid||Login.value.password!=Login.value.confirmpass"
                                href="javascript:void(0)"
                                class=" btn text-center style2-input text-white fw-600 btn-info  border-0 p-0"
                                style="font-size: 24px;width: 100%;">SUBMIT</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>