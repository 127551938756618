<div class="main-wrap ">

    <!-- <div class="help">
        <img src="assets/images/Icon material-help-outline.png"><span>คำแนะนำ</span>
    </div> -->
    <div class="row">
        <div class="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style="background-image: url(assets/images/theme6.png)">
            <div class="web-content">

                <div class="logo">
                    <img src="assets/images/aimc_logo.png">
                </div>
                <h4>
                    ระบบอบรมออนไลน์
                </h4>
                <h4>
                    LMS Expert E-LEARNING
                </h4>
            </div>
            <!-- <img class="bg1" src="../../assets/images/bg1.png"> -->
        </div>

        <div class="col-xl-6 vh-lg-100 align-items-center d-flex rounded-lg overflow-hidden bg-box-form">
            <div class="back-btn" routerLink="/login">
                <img src="../../assets/images/Icon awesome-arrow-circle-left.png" alt=""> &nbsp;&nbsp;&nbsp; <span style="font-size: 18px;">กลับหน้าล็อกอิน</span>
            </div>
            <div class="card shadow-none border-0 ml-auto mr-auto login-card">
                <div class="card-body rounded-0 text-left">

                    <div class="logo_mobile">
                        <img src="assets/images/aimc_logo.png">
                    </div>

                    <h2 class="display2-md-size mb-3 font-bold">ลืมรหัสผ่าน</h2>
                    <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="show_error">
                        <strong>มีข้อผิดพลาด !</strong><br /> ไม่พบข้อมูลบัตรประชาชนที่ป้อน
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form [formGroup]="Login">
                        <div class="form-group icon-input mb-3">
                            <!-- <i class="font-sm ti-email  pr-0" style="color: #444547;"></i> -->
                            <img class='user' src="../../assets/images/Icon awesome-id-card.png">
                            <div class="line"></div>
                            <input formControlName="username" type="text"
                                class="style2-input pl-6 form-control text-grey-900 font-xsss fw-600"
                                placeholder="บัตรประชาชน 13 หลัก" />
                            <div class="mt-1 text-danger" *ngIf="Login.get('username').hasError('required')&&Login.get('username').touched">
                                จำเป็นต้องป้อนข้อมูล
                            </div>
                        </div>
                        <div>
                            <h5>รับรหัสผ่านทาง</h5>
                        </div>
                        <div style="height: 40px;width: 100%;">
                            <div class="form-check text-left mb-3" style=" float: left; width: 150px;">
                                <input type="radio" name="type" formControlName="type" value="email"
                                    class="form-check-input mt-2" id="exampleCheck1" />
                                <label class="form-check-label font-xs text-grey-800" for="exampleCheck1"
                                    style="margin-top: 6px;">อีเมล์ </label>

                            </div>
                            <div class="form-check text-left mb-3" style=" float: left; width: 150px;">
                                <input type="radio" name="type" formControlName="type" value="sms"
                                    class="form-check-input mt-2" id="exampleCheck2" />
                                <label class="form-check-label font-xs text-grey-800" for="exampleCheck2"
                                    style="margin-top: 6px;">มือถือ (SMS) </label>

                            </div>
                        </div>

                    </form>
                    <div class="col-sm-12 p-0 text-left">
                        <div class="form-group mb-1">
                            <button (click)="login_func()" [disabled]="Login.invalid" href="javascript:void(0)"
                                class=" btn text-center style2-input text-white fw-600 btn-info border-0 p-0"
                                style="width: 100%;">ส่งข้อมูล</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>