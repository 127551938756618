<app-header></app-header>
<div class="full">
    <div class="header">
        <div class="container">

            <div class="row">
                <div class="backhome">
                    <div class="badge badge-waning" routerLink="/home">
                        < กลับ </div>
                    </div>
                    <div class="topic">
                        <h3> {{topics.course.cg_title}}</h3>
                    </div>

                </div>

                <div class="round">
                    <span>รอบ {{topics.course.rt_title}}</span>
                    <span>ระยะเวลา {{topics.course.time}}</span>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 ">
                    <div class="course">
                        <h2 class="text-bold" style="font-weight: bold;">
                            หัวข้อการอบรม ({{topics.data.length}})
                        </h2>
                        <strong class="mb-sm" *ngIf="get_not_finish().length!=0">ยังอบรมยังไม่จบ
                            ({{get_not_finish().length}})</strong>
                        <ul class="list-group">
                            <li class="list-group-item " *ngFor="let item of get_not_finish()">
                                <div class="right-topic" (click)="goto_url(courseid,item.code_uri)">
                                    {{item.time}}

                                </div>
                                <div class="img" (click)="goto_url(courseid,item.code_uri)">
                                    <img src="../../assets/images/tutorial.png" alt=""
                                        [src]="item.icon==''?'../../assets/images/tutorial.png':'https://www.lmsexpert.org/api/'+item.icon">
                                </div>
                                <div class="detail" (click)="goto_url(courseid,item.code_uri)">
                                    <div class="name">
                                        {{item.title}}
                                    </div>
                                    <div class="type">
                                        <div *ngIf="item.side!=''" style="display: inline-block;"> {{item.side}} •
                                        </div> <span *ngIf="item.force_topic||topics.course.is_custom=='0'">
                                            บังคับเลือก</span>
                                        <span
                                            *ngIf="!(item.force_topic||topics.course.is_custom=='0')">เลือกอิสระ</span>
                                    </div>
                                    <div class="percen-box">
                                        <div class="procress">
                                            <div class="percen" [ngStyle]="{'width': item.percen+'%'}"></div>
                                        </div>
                                        {{item.percen}}%
                                    </div>
                                </div>
                                <div class="download" *ngIf="item.cer!=''" (click)="download(item.cer)">
                                    ดาวน์โหลด Certificate
                                </div>
                                <!-- <div class="download" *ngIf="item.is_exp" >
                                หมดอายุ
                                </div> -->
                            </li>

                        </ul>
                        <strong class="mb-sm" *ngIf="get_finish().length!=0">อรบรมจบแล้ว
                            ({{get_finish().length}})</strong>
                        <ul class="list-group">
                            <li class="list-group-item " *ngFor="let item of get_finish()">
                                <div class="right-topic" (click)="goto_url(courseid,item.code_uri)">
                                    {{item.time}}

                                </div>
                                <div class="img" (click)="goto_url(courseid,item.code_uri)">
                                    <img src="../../assets/images/tutorial.png" alt=""
                                        [src]="item.icon==''?'../../assets/images/tutorial.png':'https://www.lmsexpert.org/api/'+item.icon">
                                </div>
                                <div class="detail" (click)="goto_url(courseid,item.code_uri)">
                                    <div class="name">
                                        {{item.title}}
                                    </div>
                                    <div class="type">
                                        <div *ngIf="item.side!=''" style="display: inline-block;"> {{item.side}} •
                                        </div> <span *ngIf="item.force_topic||topics.course.is_custom=='0'">
                                            บังคับเลือก</span>
                                        <span
                                            *ngIf="!(item.force_topic||topics.course.is_custom=='0')">เลือกอิสระ</span>
                                    </div>
                                    <div class="percen-box">
                                        <div class="procress">
                                            <div class="percen" [ngStyle]="{'width': item.percen+'%'}"></div>
                                        </div>
                                        {{item.percen}}%
                                    </div>
                                </div>
                                <div class="download" *ngIf="item.cer!=''" (click)="download(item.cer)">
                                    ดาวน์โหลด Certificate
                                </div>
                                <div class="wait" *ngIf="item.cer==''&&item.count_take_picture==item.photo.length&&topics.course.kyc!=''">รออนุมัติ</div>
                                <div class="error"
                                    *ngIf="item.count_take_picture>item.photo.length&&topics.course.kyc!=''&&item.cer==''">
                                    มีภาพถ่ายบางภาพมีปัญหา<br />
                                    กรุณาเข้าไปถ่ายใหม่
                                </div>
                                <div class="error" *ngIf="topics.course.kyc==''&&item.cer==''">
                                    กรุณาถ่ายภาพยืนยันตัวตนใหม่
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>