<header id="top">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container ">
      <a class="navbar-brand" routerLink="/home" translate>
        <img class="logo" src="../../../assets/images/logo_header.png">
      </a>
      <div id="navbar-menu" class="collapse navbar-collapse ">
        <div class=" ml-auto dropdown">
          <a  class="dropdown-toggle " style="cursor: pointer;" id="dropdownMenuButton" data-toggle="dropdown">
            <img src="../../../assets/images/profile-2.png" [src]="'https://www.lmsexpert.org/api/profile/'+user.profile_url" class="img-thumbnail" alt="...">
            <span class="profile-name">{{user.first_name}} {{user.last_name}}</span>
          </a>
          <div class="dropdown-menu" style="margin-left: -10px; margin-top: 15px;" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" routerLink="/home">โปรไฟล์</a>
            <a class="dropdown-item" routerLink="/home">หลักสูตรที่เรียน</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">ออกจากระบบ</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>