<app-header2></app-header2>
<div class="content">
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators" style="bottom: 55px;">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item " [ngClass]="{'active':i ==0}" *ngFor="let bg of _bg;let i = index">
        <div class="cover_img"
          style="background-image: url({{bg.img}});" >
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="container">
      <div class="row treemenu" style="position: relative;" >
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <div class="card card-menu shadow-sm" routerLink="/all-courese" style="cursor: pointer;">
            <div class="card-body text-center">
              <div class="my-2 pb-2">
                <img class="rounded-circle" src="../../assets/images/NoPath - Copy (2).png" alt="" width="50">
              </div>
              <h4 class="card-subtitle mb-2 text-muted font-weight-light">แนะนำหลักสูตร</h4>
              <h5 class="card-subtitle mb-2 text-muted">Courese</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <div class="card card-menu shadow-sm" routerLink="/manual" style="cursor: pointer;">
            <div class="card-body text-center">
              <div class="my-2 pb-2">
                <img class="rounded-circle" src="../../assets/images/NoPath - Copy (3).png" alt="" width="50">
              </div>
              <h4 class="card-subtitle mb-2 text-muted font-weight-light">คู่มือการใช้งาน</h4>
              <h5 class="card-subtitle mb-2 text-muted">User manual</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <div class="card card-menu shadow-sm" routerLink="/faq" style="cursor: pointer;">
            <div class="card-body text-center">
              <div class="my-2 pb-2">
                <img class="rounded-circle" src="../../assets/images/icons8-faq-96.png" alt="" width="50">
              </div>
              <h4 class="card-subtitle mb-2 text-muted font-weight-light">คำถามที่พบบ่อย</h4>
              <h5 class="card-subtitle mb-2 text-muted">FAQ</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="desktop mobile mb-3">
    <div class="container">
      <div class="row row-course">
        <div class="col-12">
          <label class="h3 pl-2 mb-4 font-weight-bold">
            หมวดหมู่ของหลักสูตร
          </label>
        </div>
      </div>
      <div class="row row-course-list">
        <div class="col-sm-12 col-md-6 col-lg-4 mb-3" *ngFor="let know of _knowledge">
          <div class="card shadow-sm">
            <div class="card-body">
              <div style="float: left;">
                <img class="rounded-circle" src="../../assets/images/icons8-real-estate-100.png"
                  [src]="know.icon==''?'../../assets/images/icons8-real-estate-100.png':'https://www.lmsexpert.org/api/'+know.icon"
                  width="70px" alt="">
              </div>
              <div class="title-courese font-weight-light">
                <div style="display: table-cell;margin: auto;vertical-align: middle;height: 70px; padding-left: 10px;">
                  {{know.title}}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-course">
        <div class="col-12">
          <label class="h3 pl-2 my-4 font-weight-bold">
            หลักสูตรที่กำลังเปิดอบรม
          </label>
        </div>
      </div>
      <div class="row row-course-list">
        <div class="col-sm-12 col-md-4 col-lg-3 mb-3" *ngFor="let tr of _TrainingRound;let i = index">
          <div class="card card-course shadow-sm">
            <a [routerLink]="'/detail-courese/'+tr.cg_id" class="overflow-hidden">
              <img class="card-img-top zoom" src="https://images-na.ssl-images-amazon.com/images/I/71hrRnjQYxL._RI_.jpg"
                [src]="tr.icon==''?'https://images-na.ssl-images-amazon.com/images/I/71hrRnjQYxL._RI_.jpg':'https://www.lmsexpert.org/api/'+tr.icon"
                alt="">
            </a>
            <div class="card-body">
              <div class="row title-time">
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 fs-round">
                  <label class="title-round">{{tr.title_rt}}</label>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-4 fs-round">
                  {{tr.duration|number:'1.0-2'}} ชม.
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12">
                  <a [routerLink]="'/detail-courese/'+tr.cg_id">
                    <h5 class="fw-bolder">{{tr.title_cg}}</h5>
                  </a>
                  <div>
                    <label style="font-size: 14px;" class="fw-bolder">ลงได้ถึง :
                      &nbsp;</label><span>{{api.get_text_date(tr.limit_date)}}</span>
                  </div>
                  <div>
                    <label style="font-size: 14px;" class="fw-bolder">เริ่ม : &nbsp;</label>
                    <span>{{api.get_text_date(tr.time_start)}}</span>
                  </div>
                  <div>
                    <label style="font-size: 14px;" class="fw-bolder">สิ้นสุด : &nbsp;</label>
                    <span>{{api.get_text_date(tr.time_end)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center my-5">
        <button routerLink="/all-courese" class="btn btn-sm text-white btn-other-courese px-3">
          <i class="far fa-newspaper"></i>
          ดูหลักสูตรทั้งหมด
        </button>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px;">
          <img src="https://www.lmsexpert.org/assets/images/S__52920357.jpg" class="img" style="width: 100%;">
        </div>
        <div class="modal-footer" style="    background: #fff;      padding: 3px 16px;">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div> -->

  <app-footer2></app-footer2>