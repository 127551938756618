import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  constructor(private route: ActivatedRoute, public api: ConnectApiService, private router: Router) { }
  courseid = '';
  rt_id = "";
  topics = { data: [], course: { cg_title: '', rt_title: '', time: '', is_custom: '0', kyc: '', ut_id: '' } };
  ngOnInit(): void {
    this.courseid = this.route.snapshot.paramMap.get("courseid");
    this.rt_id = this.route.snapshot.paramMap.get("rt_id");
    console.log(this.courseid, this.rt_id);
    this.api.getData("get_topic2/" + this.rt_id + "/" + this.courseid).then((res: any) => {
      console.log(res);
      this.topics = res;
    })
  }
  download(cer) {
    window.open('http://www.lmsexpert.org/api/certificate/' + cer, '_blank').focus();
  }
  get_not_finish() {
    return this.topics.data.filter(item => item.percen - 0 !== 100);
  }
  get_finish() {
    return this.topics.data.filter(item => item.percen - 0 == 100);
  }
  goto_url(courseid, code_uri) {
    // [routerLink]="'/topic/'+courseid+'/'+item.code_uri"
    if (this.topics.course.kyc == '') {
      console.log(this.topics);
      this.router.navigate(['/kyc/' + this.topics.course.ut_id + '/' + this.courseid + '/' + code_uri+"/"+this.rt_id]);
    } else {
      this.router.navigate(['/topic/' + courseid + '/' + code_uri+'/'+this.rt_id])
    }
  }

}
