<div class="main-wrap ">
    <!-- <div class="help">
        <img src="assets/images/Icon material-help-outline.png"><span>คำแนะนำ</span>
    </div> -->
    <div class="row">
        <div class="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style="background-image: url(assets/images/theme6.png)">
            <div class="web-content">

                <div class="logo">
                    <img src="assets/images/aimc_logo.png">
                </div>
                <h4>
                    ระบบอบรมออนไลน์
                </h4>
                <h4>
                    LMS Expert E-LEARNING
                </h4>
            </div>
            <!-- <img class="bg1" src="../../assets/images/bg1.png"> -->
        </div>

        <div class="col-xl-6 vh-lg-100 align-items-center d-flex rounded-lg overflow-hidden bg-box-form">
            <div class="card shadow-none border-0 ml-auto mr-auto login-card">
                <div class="card-body rounded-0 text-left">
                    <div class="logo_mobile">
                        <img src="assets/images/aimc_logo.png">
                    </div>
                    <h2 class="display2-md-size mb-3 font-bold">เข้าสู่ระบบ</h2>
                    <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="show_error">
                        <strong>มีข้อผิดพลาด !</strong><br /> ข้อมูลเข้าระบบไม่ถูกต้องลองใหม่
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="show_success">
                        <strong>เข้าสู่ระบบสำเร็จ</strong><br /> กำลังไปหน้าหลักสมาชิก...
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form [formGroup]="Login">
                        <div class="form-group icon-input mb-3">
                            <!-- <i class="font-sm ti-email  pr-0" style="color: #444547;"></i> -->
                            <img class='user' src="../../assets/images/Icon awesome-id-card.png">
                            <div class="line"></div>
                            <input formControlName="username" type="text" (keyup.enter)="user_enter()"  
                                class="style2-input pl-6 form-control text-grey-900 font-xsss fw-600"
                                placeholder="บัตรประชาชน 13 หลัก" />
                            <div class="mt-1 text-danger"
                                *ngIf="Login.get('username').hasError('required')&&Login.get('username').touched">
                                จำเป็นต้องป้อนข้อมูล
                            </div>
                        </div>
                        <div class="form-group icon-input mb-3">
                            <img class="pass" src="../../assets/images/Icon awesome-lock.png">
                            <div class="line"></div>
                            <input type="Password" class="style2-input pl-6 form-control text-grey-900 font-xss ls-3"
                                placeholder="รหัสผ่าน" [formControlName]="'password'" #password  (keyup.enter)="pass_enter()" />
                            <div class="mt-1 text-danger" *ngIf="Login.get('password').hasError('required')&&Login.get('password').touched">
                                จำเป็นต้องป้อนข้อมูล
                            </div>
                        </div>
                        <div class="form-check text-left mb-3">
                            <input type="checkbox" class="form-check-input mt-2" id="exampleCheck1" />
                            <label class="form-check-label font-xs text-grey-800" for="exampleCheck1"
                                style="margin-top: 6px;">จำฉันไว้ </label>
                            <a routerLink="/forgot" class="font-xs text-grey-800 mt-1 float-right">ลืมรหัสผ่าน ?</a>
                        </div>
                    </form>
                    <div class="col-sm-12 p-0 text-left">
                        <div class="form-group mb-1">
                            <button (click)="login_func()" [disabled]="Login.invalid" href="javascript:void(0)"
                                class=" btn text-center style2-input text-white fw-600 btn-info  border-0 p-0"
                                style="font-size: 24px;width: 100%;">เข้าสู่ระบบ</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>