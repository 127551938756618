<app-header></app-header>
<div class="full">
    <div class="header">
        <div class="container">
            <h3>หน้าหลัก (Dashboard)</h3>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-5 col-sm-12">
                <div class="profile-list">
                    <h3>
                        ยินดีต้อนรับ
                    </h3>
                    <div class="profile">
                        <div class="img">
                            <button class="btn" (click)="upload_profile(content)">อัพโหลด</button>
                            <img src="../../assets/images/profile-2.png" style="width: 130px; height: 130px;"
                                [src]="'https://www.lmsexpert.org/api/profile/'+user.profile_url" alt="">
                        </div>
                        <div class="detail">
                            <h4>
                                {{user.prefix+" "+user.first_name+" "+user.last_name}}
                            </h4>
                            <div>
                                <img src="../../assets/images/Icon material-email.png" alt="">{{user.user_email}}

                            </div>
                            <div>
                                <img src="../../assets/images/Icon metro-mobile.png" alt=""> {{user.phone}}
                            </div>
                            <div>
                                <img src="../../assets/images/Icon awesome-id-card.png" alt=""> {{user.inhouse_name}}
                            </div>
                            <div *ngIf="user.last_login!='0000-00-00 00:00:00'">
                                <img src="../../assets/images/Icon awesome-sign-in-alt.png" alt="">
                                <small>{{get_text_datetime(user.last_login)}} (เข้าระบบล่าสุด)</small>
                            </div>
                            <div class="badge badge-info badge-sm" (click)="upload_profile(contentProfile)">
                                <img src="../../assets/images/Icon feather-edit.png" alt=""> แก้ไขข้อมูลส่วนตัว
                            </div>
                            <div class="badge badge-waning" (click)="upload_profile(change_password)">
                                <img src="../../assets/images/Icon awesome-key.png" alt=""> เปลี่ยนรหัสผ่าน
                            </div>
                            <!-- <div class="logout">
                                <img src="../../assets/images/Icon ionic-ios-log-out.png" alt="">
                                <span>
                                    ออกจากระบบ
                                </span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-sm-12">
                <div class="course">
                    <h1>
                        หลักสูตรที่ลงทะเบียน
                    </h1>
                    <div>
                        <ul class="list-group">
                            <li class="list-group-item " *ngFor="let item of courses_not_exp"
                                (click)="item_click(item,agree)">
                                <div class="img">
                                    <img src="../../assets/images/online-learning.png" alt=""
                                        [src]="item.icon==''?'../../assets/images/online-learning.png':'https://www.lmsexpert.org/api/'+item.icon">
                                    <button class="btn btn-success btn-sm">เข้าอบรม</button>
                                </div>
                                <div class="detail">
                                    <div class="percen-box">
                                        <div class="procress">
                                            <div class="percen" [ngStyle]="{'width': item.percen+'%'}"></div>
                                        </div>
                                        {{item.percen}}%
                                    </div>
                                    <div class="type">
                                        รอบ {{item.round}}
                                    </div>
                                    <div class="name">
                                        {{item.course}}
                                    </div>
                                    <div class="time">
                                        {{get_text_date(item.time_start)}} - {{get_text_date(item.time_end)}}
                                    </div>
                                    <div class="round">
                                        เวลา {{item.time}} <img style="width: 20px;"
                                            src="../../assets/images/medal.png">
                                        <span style="font-size: 13px;">
                                            Course certificate
                                        </span>
                                    </div>

                                    <div class="exp_course" *ngIf="item.is_exp&&item.data_start_text<=0">
                                        หมดอายุ
                                    </div>
                                    <span>
                                        ลงทะเบียนเมื่อ <i>
                                            {{item.cre_date}}
                                        </i>
                                    </span>
                                    <small *ngIf="!item.is_exp&&item.data_start_text<=0" class="exp_class">
                                        เหลือเวลาอีก {{item.date_balance}} วัน
                                    </small>
                                    <span class="exp_class" *ngIf="item.data_start_text>0" style="    background-color: #0dcaf0;
                                    color: #fff;">
                                        รออีก {{item.data_start_text}} วัน
                                    </span>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div>
                        <ul class="list-group">
                            <li class="list-group-item " *ngFor="let item of courses_exp" [routerLink]="'/'+item.link">
                                <div class="img">
                                    <img src="../../assets/images/online-learning.png" alt=""
                                        [src]="item.icon==''?'../../assets/images/online-learning.png':'https://www.lmsexpert.org/api/'+item.icon">
                                </div>
                                <div class="detail">
                                    <div class="percen-box">
                                        <div class="procress">
                                            <div class="percen" [ngStyle]="{'width': item.percen+'%'}"></div>
                                        </div>
                                        {{item.percen}}%
                                    </div>
                                    <div class="type">
                                        รอบ {{item.round}}
                                    </div>
                                    <div class="name">
                                        {{item.course}}
                                    </div>
                                    <div class="time">
                                        {{get_text_date(item.time_start)}} - {{get_text_date(item.time_end)}}
                                    </div>
                                    <div class="round">
                                        เวลา {{item.time}} <img style="width: 20px;"
                                            src="../../assets/images/medal.png">
                                        <span style="font-size: 13px;">
                                            Course certificate
                                        </span>
                                    </div>
                                    <div class="exp_course" *ngIf="item.is_exp">
                                        หมดอายุ
                                    </div>

                                    <span>
                                        ลงทะเบียนเมื่อ <i>
                                            {{item.cre_date}}
                                        </i>
                                    </span>
                                    <small *ngIf="!item.is_exp" class="ex_class">
                                        เหลือเวลาอีก {{item.date_balance}} วัน
                                    </small>

                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #agree let-modal>
    <div class="modal-body text-left p-4 agree_modal" style="padding:5px 5px 0px 5px;">
        <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="../../assets/images/Icon metro-cross.png">
        </div>
        <h4 class="font-bold"> กรุณาอ่านคำชี้แจงก่อนคลิกปุ่ม “ยอมรับ” เพื่อเข้าสู่การอบรม</h4>

        <p>

            ในการอบรมหลักสูตรนี้ ท่านจะต้องยืนยันตัวตนโดยการถ่ายภาพบัตรประจำตัวประชาชน (เฉพาะด้านหน้า),
            ถ่ายภาพหน้าตรงก่อนการอบรม และในระหว่างอบรมระบบจะสุ่มให้ท่านถ่ายภาพหน้าตรงยืนยันตัวตนอีกครั้ง หรือมีแบบฝึกหัด
            เพื่อเป็นกิจกรรมเสริมในหลักสูตรหรือรายวิชานั้นๆ ทั้งนี้เพื่อผลประโยชน์ของตัวท่านเอง
            ศูนย์ความรู้วิชาชีพทางการเงิน AIMC
            ขอแจ้งให้ท่านปฏิบัติตามกฎระเบียบและเงื่อนไขในการอบรมเพื่อรับรองชั่วโมงอบรมอย่างเคร่งครัด ดังนี้
        </p>
        <p>
            1. ให้ท่านเตรียมบัตรประจำตัวประชาชน (ฉบับจริง)
        </p>
        <p>
            2. ในระหว่างการถ่ายภาพทั้งบัตรประจำตัวประชาชนและภาพหน้าตรง ต้องมีแสงสว่างเพียงพอ ภาพต้องมีความชัดเจน
            ไม่สวมแว่นดำ, หมวก หรือหน้ากาก
        </p>
        <p>
            3. ภาพที่ถ่ายต้องเป็นภาพที่ถ่ายจากกล้องระหว่างอบรมเท่านั้น ไม่อนุญาตให้ใช้ภาพเดิมที่เคยมีในอุปกรณ์อื่นมาใช้
        </p>
        <p>
            4. หากพบว่าท่านทุจริต ศูนย์ความรู้วิชาชีพทางการเงิน AIMC จะไม่รับรองชั่วโมงอบรมในหลักสูตรหรือรายวิชานั้นๆ
            และรายงานพฤติกรรมของท่านไปยังหน่วยงานกำกับ เช่น สำนักงาน ก.ล.ต. และสังกัดของท่าน
        </p>
        <div class="mt-2">
            <input type="checkbox" id="agree" [(ngModel)]="agreecheck">
            <label for="agree" class="font-bold"
                style="font-weight: bold; margin-left: 10px;">ข้าพเจ้าได้อ่านและทำความเข้าใจคำชี้แจงก่อนเข้าสู่การอบรมเรียบร้อยแล้ว</label>
        </div>
        <div>
            <button class="btn btn-info btn-sm mx-auto d-block" [disabled]="!agreecheck" (click)="agree_click(modal)">
                ยอมรับ
            </button>
        </div>

    </div>
</ng-template>
<ng-template #content let-modal>
    <div class="modal-body " style="padding:5px 5px 0px 5px;">
        <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="../../assets/images/Icon metro-cross.png">
        </div>
        <h4>อัพโหลดภาพโปรไฟล์</h4>
        <input type="file" #fileInput accept="image/*" style="display: none;" (change)="selectFiles($event)" />
        <div class="upload" (click)="fileInput.click()">
            <div>
                +
                <span>Upload Picture</span>
            </div>
        </div>
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
            format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

        <!-- <div class="upload" *ngIf="url!=''" (click)="fileInput.click()">
            <img [src]="'https://www.lmsexpert.org/api/profile/'+url" style="width: 100%;" alt="">
        </div> -->
        <div *ngIf="message!=''" style="color: red;">{{message}}</div>
        <button class="btn btn-info btn-sm " [disabled]="!imageChangedEvent" (click)="save_profile(modal)">
            บันทึก
        </button>
    </div>
</ng-template>
<ng-template #contentProfile let-modal>
    <div class="modal-body profile_model">
        <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="../../assets/images/Icon metro-cross.png">
        </div>
        <h4>แก้ไขข้อมูลส่วนตัว</h4>
        <form [formGroup]="profile" (ngSubmit)="change_prifile(modal)">
            <div class="form-group row">
                <label for="inputPassword5" class="col-sm-4 col-form-label">รหัสประชาชน</label>
                <div class="col-sm-8">
                    <input type="text" [disabled]="true" disabled class="form-control  form-control-sm"
                        id="inputPassword5" [formControlName]="'cid'" placeholder="บัตรประชาชน">
                </div>
            </div>
            <div class="form-group row" ngClass="{
                'has-error': !profile.get('prefix').valid && profile.get('prefix').touched,
                'has-feedback': !profile.get('prefix').valid && profile.get('prefix').touched
            }">
                <label for="inputEmail1" class="col-sm-4 col-form-label">คำนำหน้า</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control form-control-sm" id="inputEmail1"
                        [formControlName]="'prefix'" placeholder="ชื่อ-สกุล">

                    <div class="invalid-feedback">Example invalid custom select feedback</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail2" class="col-sm-4 col-form-label">ชื่อ</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control form-control-sm" id="inputEmail2"
                        [formControlName]="'first_name'" placeholder="ชื่อ-สกุล">
                </div>
            </div>

            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-4 col-form-label">นามสกุล</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control form-control-sm" id="inputEmail3"
                        [formControlName]="'last_name'" placeholder="ชื่อ-สกุล">
                </div>
            </div>
            <div class="form-group row">
                <label for="inputPassword3" class="col-sm-4 col-form-label">อีเมล์</label>
                <div class="col-sm-8">
                    <input type="email" class="form-control  form-control-sm" id="inputPassword3"
                        [formControlName]="'user_email'" placeholder="อีเมล์">
                </div>
            </div>
            <div class="form-group row">
                <label for="inputPassword4" class="col-sm-4 col-form-label">โทรศัพท์</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control  form-control-sm" id="inputPassword4"
                        [formControlName]="'phone'" placeholder="โทรศัพท์">
                </div>
            </div>

            <!-- <div class="form-group row">
                <label for="inputPassword6" class="col-sm-4 col-form-label">สังกัด/หน่วยงาน</label>
                <div class="col-sm-8">
                    <select placeholder="สังกัด/หน่วยงาน" class="form-control form-control-sm" id="inputPassword6"
                        [formControlName]="'in_h_id'">
                        <option *ngFor="let inh of inhouse" value="" [value]="inh.in_h_id ">{{inh.company_name}}
                        </option>
                    </select>
                </div>
            </div> -->
            <div class="form-group row">
                <label for="inputPassword6" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                    <button class="btn btn-info btn-sm " type="submit">
                        Update
                    </button>
                </div>
            </div>
        </form>

    </div>
</ng-template>
<ng-template #change_password let-modal>
    <div class="modal-body profile_model">
        <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="../../assets/images/Icon metro-cross.png">
        </div>
        <h4>แก้ไขข้อมูลส่วนตัว</h4>
        <form [formGroup]="change_pass" (ngSubmit)="change_password_func(modal)">
            <div class="form-group row">
                <label for="inputEmail1" class="col-sm-4 col-form-label">รหัสผ่านเดิม</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control form-control-sm "
                        [ngClass]="{'is-invalid': change_pass.get('old_pass').invalid && change_pass.get('old_pass').touched}"
                        id="inputEmail1" [formControlName]="'old_pass'" placeholder="รหัสผ่านเดิม">
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': change_pass.get('old_pass').invalid && change_pass.get('old_pass').touched}">
                        รหัสผ่านเดิมไม่ถูกต้อง</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail2" class="col-sm-4 col-form-label">รหัสผ่านใหม่</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control form-control-sm" id="inputEmail2"
                        [formControlName]="'new_pass'" placeholder="รหัสผ่านใหม่"
                        [ngClass]="{'is-invalid': change_pass.get('new_pass').invalid && change_pass.get('new_pass').touched}">
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': change_pass.get('new_pass').invalid && change_pass.get('new_pass').touched}">
                        6 ตัวอักษรขึ้นไป</div>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-4 col-form-label">ยืนยันรหัสผ่านใหม่</label>
                <div class="col-sm-8">
                    <input type="password" class="form-control form-control-sm" id="inputEmail3"
                        [formControlName]="'confirm_pass'" placeholder="ยืนยันรหัสผ่านใหม่"
                        [ngClass]="{'is-invalid': change_pass.get('new_pass').value!= change_pass.get('confirm_pass').value && change_pass.get('confirm_pass').touched}">
                    <div class="invalid-feedback"
                        [ngClass]="{'d-block': change_pass.get('new_pass').value!= change_pass.get('confirm_pass').value && change_pass.get('confirm_pass').touched}">
                        ต้องเหมือนกันกับรหัสผ่านใหม่</div>

                </div>
            </div>
            <div class="form-group row">
                <label for="inputPassword6" class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                    <button class="btn btn-info btn-sm "
                        [disabled]="change_pass.get('new_pass').value!= change_pass.get('confirm_pass').value||change_pass.invalid"
                        type="submit">
                        Update
                    </button>
                </div>
            </div>
        </form>

    </div>
</ng-template>

<app-footer></app-footer>