<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">

        <a routerLink="/" class="navbar-brand">
            <img class="d-inline-block align-top img-brand" src="../../assets/images/aimc_logo.png"
                alt="AIMC Elearning">
            <label class="text-white pl-2" style="cursor: pointer">
                <span class="brand1">ระบบอบรมออนไลน์</span>
                <span class="brand2">LMS Expert Learning</span>
            </label>
        </a>

        <a class="nav-link text-white d-block d-sm-block d-lg-none d-xl-none ml-auto" routerLink="/login">
            <button class="btn btn-sm text-white btn-login">เข้าสู่ระบบ</button>
        </a>

        <button class="navbar-toggler text-white mx-2" type="button" data-toggle="collapse"
            data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false"
            aria-label="Toggle navigation" style="outline: 0;">
            <i class="fas fa-align-justify text-white"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarToggleExternalContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link text-white mt-1" routerLink="/">หน้าหลัก</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-white mt-1" routerLink="/about-us">เกี่ยวกับเรา</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-white mt-1" routerLink="/contact-us">ติดต่อเรา</a>
                </li>
                <li class="nav-item d-none d-sm-none d-md-none d-lg-block" *ngIf="_passcodeState==1">
                    <a class="nav-link text-white" (click)="logout()">
                        <button class="btn btn-sm text-white btn-login">ออกจากระบบ</button>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>