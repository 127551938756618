import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ConnectApiService {
    // public base_url = 'https://sale.deltafood.co/api/Front_api/';
    // public base_url = 'https://e-learning.aimc.or.th/api/Front_api/';
    public base_url = 'https://www.lmsexpert.org/api/Front_api/';
    public error_text = '';
    public onLogin: any;
    constructor(private http: HttpClient, public router: Router) { }

    upload_user(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const req = new HttpRequest('POST', `${this.base_url}upload_user`, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req);
    }
    upload(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const req = new HttpRequest('POST', `${this.base_url}upload_file`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(req);
    }
    upload_base64(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const req = new HttpRequest('POST', `${this.base_url}upload_file_base64`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(req);
    }
    upload_profile(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const req = new HttpRequest('POST', `${this.base_url}b_admin/upload_file`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(req);
    }
    upload_csv(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const req = new HttpRequest('POST', `${this.base_url}B_round/upload_file`, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req);
    }
    upload_exam(file: File, eg_id): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('eg_id', eg_id);
        const req = new HttpRequest('POST', `${this.base_url}B_exam/upload_file`, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req);
    }

    getFiles(): Observable<any> {
        return this.http.get(`${this.base_url}/files`);
    }

    baseURL() {
        return 'https://www.lmsexpert.org/api/'
    }


    confirm_temove(confirm, cancel) {
        Swal.fire({
            title: 'ยืนยันการลบ?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'ลบ',
            cancelButtonText: "ปิดออก",

        }).then((result) => {
            if (result.isConfirmed) {
                confirm();
            } else {
                cancel();
            }
        })
    }
    confirm_send_test(confirm, cancel) {
        Swal.fire({
            title: 'ยืนยันการส่งข้อสอบ?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#444547',
            confirmButtonText: 'ส่งข้อสอบ',
            cancelButtonText: "ปิดออก",

        }).then((result) => {
            if (result.isConfirmed) {
                confirm();
            } else {
                cancel();
            }
        })
    }
    success(text) {
        if (text == '') {
            text = "บันทึกเรียบร้อยแล้ว";
        }
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: text,
            showConfirmButton: false,
            timer: 1500
        })
    }
    error(text) {
        if (text == '') {
            text = "มีปัญหาในการบันทึก";
        }
        Swal.fire({
            title: 'Error!',
            text: text,
            icon: 'error',
            confirmButtonText: 'ตกลง'
        });
    }
    log(text) {
        if (text == '') {
            text = "";
        }
        Swal.fire({
            text: text,
            confirmButtonText: 'ตกลง'
        });
    }
    getData_nologin(segment) {
        return new Promise((resolve, reject) => {

            let headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            this.http.get(this.base_url + segment, { headers: headers })
                .subscribe((res: any) => {
                    resolve(res);
                }, (err) => {
                    if (err.status == 0) {
                        console.log(err);
                        reject(err);
                    }
                    reject(err);
                });
        });
    }
    getData(segment) {
        return new Promise((resolve, reject) => {
            let auth_token = localStorage.getItem("auth_token");
            if (!auth_token) {
                localStorage.setItem('auth_token', null);
                this.storage_set("userdata", null);
                this.router.navigate(['/login']);
            }
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': auth_token
            });
            this.http.get(this.base_url + segment, { headers: headers })
                .subscribe((res: any) => {
                    if (typeof res.logout === 'undefined') {
                        resolve(res);
                    } else {
                        localStorage.setItem('auth_token', null);
                        this.storage_set("userdata", null);
                        this.router.navigate(['/login']);
                    }
                }, (err) => {
                    if (err.status == 0) {
                        console.log(err);
                        reject(err);
                    }
                    reject(err);
                });
        });
    }

    login(objdata) {
        return new Promise((resolve, reject) => {
            let headers = new HttpHeaders();
            this.http.post(this.base_url + 'login', JSON.stringify(objdata), { headers: headers })
                .subscribe((res: any) => {
                    resolve(res);

                }, (err) => {
                    if (err.status == 0) {
                        reject(err);
                    }
                });
        });
    }
    forgot(objdata) {
        return new Promise((resolve, reject) => {
            let headers = new HttpHeaders();
            this.http.post(this.base_url + 'forgot', JSON.stringify(objdata), { headers: headers })
                .subscribe((res: any) => {
                    resolve(res);

                }, (err) => {
                    if (err.status == 0) {
                        reject(err);
                    }
                });
        });
    }
    postData_nologin(segment, objdata) {
        return new Promise((resolve, reject) => {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            this.http.post(this.base_url + segment, JSON.stringify(objdata), { headers: headers })
                .subscribe(res => {
                    resolve(res);
                }, (err) => {
                    if (err.status == 0) {
                        reject(err);
                    }
                });
        });
    }
    postData(segment, objdata) {
        return new Promise((resolve, reject) => {
            let auth_token = localStorage.getItem("auth_token");
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': auth_token
            });

            this.http.post(this.base_url + segment, JSON.stringify(objdata), { headers: headers })
                .subscribe(res => {
                    resolve(res);
                }, (err) => {
                    if (err.status == 0) {
                        reject(err);
                    } 
                });
        });
    }
    get_text_datetime(date) {
        let d1 = date.split(" ");
        let d2 = d1[0].split("-");
        let d3 = d1[1].split(":");
        var monthNamesThai = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
            "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        return d2[2] + " " + monthNamesThai[d2[1] - 1] + " " + (d2[0] - 0 + 543) + " " + d3[0] + ":" + d3[1] + " น.";

    }
    get_text_date(date) {
        let d2 = date.split("-");
        var monthNamesThai = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
            "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        return d2[2] + " " + monthNamesThai[d2[1] - 1] + " " + (d2[0] - 0 + 543);

    }
    storage_set(key, val) {
        localStorage.setItem(key, JSON.stringify(val));
    }

    storage_get(key) {
        return localStorage.getItem(key);
    }
}
