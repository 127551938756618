import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectApiService } from './connect.api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'elerning';
  time_logout = 1800;//วินาที
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.time_logout = 1800;//วินาที
  }
  constructor(public api: ConnectApiService, public router: Router) {

  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    let time = null;
    time = setInterval(() => {
      if (this.time_logout > 0) {
        // console.log(this.time_logout);
        this.time_logout--;

        if (this.time_logout == 0) {
          // console.log("logout");
          this.logout();
        } else if (this.time_logout == 20) {
          // this.show_logout();
        }
      } else {
        clearInterval(time);
      }
    }, 1000);
  }
  logout() {
    localStorage.setItem('auth_token', null);
    this.api.storage_set("userdata", null);
    this.router.navigate(['/login']);
  }
}
