<app-header></app-header>
<div class="full">
    <div class="header">
        <div class="container">
            <div class="row">
                <div class="btn_back badge badge-waning" (click)="back()">
                    < กลับ
                 </div>
                 <div class="btn_verify"><h3>การยืนยันตัวตน</h3></div>
            </div>
        </div>
    </div>

    <div class="container body" style=" min-height: calc(100vh - 210px);">
        <div class="row">
            <div class="col-md-10 col-sm-12 " style="margin:0 auto">
                <div class="course">

                    <div class="d-none d-md-block d-lg-block">
                        <section class="signup-step-container">
                            <div class="container">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-12">
                                        <div class="wizard">
                                            <div class="wizard-inner">
                                                <div class="connecting-line"></div>
                                                <ul class="nav nav-tabs" role="tablist">
                                                    <li role="presentation" class=""
                                                        [ngClass]="{'active': step>1,'disabled':step<=1}">
                                                        <a href="#step1" data-toggle="tab" aria-controls="step1"
                                                            role="tab" aria-expanded="true">

                                                            <span class="round-tab">
                                                                <span *ngIf="step<=1">
                                                                    1
                                                                </span>
                                                                <img *ngIf="step>1"
                                                                    src="../../assets/images/Icon ionic-md-checkmark.png"
                                                                    style="width: 25px;" alt="">
                                                            </span>
                                                            <i>ถ่ายภาพบัตรประชาชน</i></a>
                                                    </li>
                                                    <li role="presentation" class=""
                                                        [ngClass]="{'active': step>2,'disabled':step<=2}">
                                                        <a href="#step2" data-toggle="tab" aria-controls="step2"
                                                            role="tab"><span class="round-tab">
                                                                <span *ngIf="step<=2">
                                                                    2
                                                                </span>
                                                                <img *ngIf="step>2"
                                                                    src="../../assets/images/Icon ionic-md-checkmark.png"
                                                                    style="width: 25px;" alt="">

                                                            </span>
                                                            <i>ถ่ายภาพหน้าตรง</i></a>
                                                    </li>
                                                    <li role="presentation" class=""
                                                        [ngClass]="{'active': step>3,'disabled':step<=3}">
                                                        <a href="#step3" data-toggle="tab" aria-controls="step3"
                                                            role="tab"><span class="round-tab"> <span *ngIf="step<=3">
                                                                    3
                                                                </span>
                                                                <img *ngIf="step>3"
                                                                    src="../../assets/images/Icon ionic-md-checkmark.png"
                                                                    style="width: 25px;" alt=""></span>
                                                            <i>บันทึกข้อมูล</i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <form role="form" action="index.html" class="login-box">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="text-center">

                                                            <button class="btn btn-info" (click)="take_photo()">
                                                                <img src="../../assets/images/Icon material-camera-alt.png"
                                                                    style="width: 16px;" alt=""> ถ่ายรูปบัตร
                                                            </button>

                                                            <div class="idcard text-center" *ngIf="images.idcard">
                                                                <img src="../../assets/images/itg-197.png"
                                                                    [src]="images.idcard._imageAsDataUrl" alt="">
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="text-center">
                                                            <button class="btn btn-info" *ngIf="step>1"
                                                                (click)="take_photo_people()">
                                                                <img src="../../assets/images/Icon material-camera-alt.png"
                                                                    style="width: 16px;" alt=""> ถ่ายรูปหน้าตรง
                                                            </button>
                                                        </div>
                                                        <div class="idcard text-center" *ngIf="images.profile">
                                                            <img src="../../assets/images/itg-197.png"
                                                                [src]="images.profile._imageAsDataUrl" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="text-center">
                                                            <button class="btn btn-info" *ngIf="step>2"
                                                                (click)="save()">
                                                                <img src="../../assets/images/Icon awesome-save.png"
                                                                    style="width: 16px;" alt=""> บันทึกข้อมูล
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="d-block d-sm-block d-xs-block  d-md-none">
                        <div class="my-5">
                            <div class="steps" id="stepWizard">
                                <div class="step position-relative">
                                    <div class="step-heading position-static">
                                        <a class="" role="button" data-toggle="collapse" aria-expanded="true"
                                            aria-controls="collapse1">
                                            <div
                                                class="num d-inline-flex  align-items-center justify-content-center position-relative rounded-circle "  [ngClass]="{'bg-success': step>1,'bg-white':step<=1}">
                                                <span *ngIf="step<=1">
                                                    1
                                                </span>
                                                <img *ngIf="step>1"
                                                    src="../../assets/images/Icon ionic-md-checkmark.png"
                                                    style="width: 25px;" alt="">
                                            </div>
                                            <div class="pl-3 d-inline-flex title">ถ่ายภาพบัตรประชาชน</div>
                                        </a>
                                    </div>
                                    <div class="line position-absolute"></div>

                                    <div class="pl-5 collapse show">
                                        <div class="step-body">
                                            <div class="text-center">

                                                <button class="btn btn-info" (click)="take_photo()">
                                                    <img src="../../assets/images/Icon material-camera-alt.png"
                                                        style="width: 16px;" alt=""> ถ่ายรูปบัตร
                                                </button>

                                                <div class="idcard text-center" *ngIf="images.idcard">
                                                    <img src="../../assets/images/itg-197.png"
                                                        [src]="images.idcard._imageAsDataUrl" alt="">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="step position-relative">
                                    <div class="step-heading position-static">
                                        <a class="" role="button" data-toggle="collapse" aria-expanded="true"
                                            aria-controls="collapse2">
                                            <div class="num d-inline-flex  align-items-center justify-content-center position-relative rounded-circle  "
                                                [ngClass]="{'bg-success': step>2,'bg-white':step<=2}">
                                                <span *ngIf="step<=2">
                                                    2
                                                </span>
                                                <img *ngIf="step>2"
                                                    src="../../assets/images/Icon ionic-md-checkmark.png"
                                                    style="width: 25px;" alt=""></div>
                                            <div class="pl-3 d-inline-flex title">ถ่ายภาพหน้าตรง</div>
                                        </a>
                                    </div>

                                    <div class="line position-absolute"></div>
                                    <div class="pl-5 collapse show">
                                        <div class="step-body">
                                            <div class="text-center">
                                                <button class="btn btn-info" *ngIf="step>1"
                                                    (click)="take_photo_people()">
                                                    <img src="../../assets/images/Icon material-camera-alt.png"
                                                        style="width: 16px;" alt=""> ถ่ายรูปหน้าตรง
                                                </button>
                                            </div>
                                            <div class="idcard text-center" *ngIf="images.profile">
                                                <img src="../../assets/images/itg-197.png"
                                                    [src]="images.profile._imageAsDataUrl" alt="">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="step position-relative">
                                    <div class="step-heading position-static">
                                        <a class="" role="button" data-toggle="collapse" aria-expanded="true"
                                            aria-controls="collapse2">
                                            <div class="num d-inline-flex align-items-center justify-content-center position-relative rounded-circle"
                                                [ngClass]="{'bg-success': step>3,'bg-white':step<=3}">
                                                <span *ngIf="step<=3">
                                                    3
                                                </span>
                                                <img *ngIf="step>3"
                                                    src="../../assets/images/Icon ionic-md-checkmark.png"
                                                    style="width: 25px;" alt=""></div>
                                            <div class="pl-3 d-inline-flex title">บันทึกข้อมูล</div>
                                        </a>
                                    </div>

                                    <div class="pl-5 collapse show">
                                        <div class="step-body text-center">
                                            <button class="btn btn-info" style="margin:10px 0 30px 0; " *ngIf="step>2" (click)="save()">
                                                <img src="../../assets/images/Icon awesome-save.png"
                                                    style="width: 16px;" alt=""> บันทึกข้อมูล
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #take_photo_idcard let-modal class="">

    <div class="modal-body camera">
        <!-- <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="../../assets/images/Icon metro-cross.png">
        </div> -->
        <div class="row">
            <div class="col col-sm-12">
                <div class="phone">
                    <div class="mic"></div>
                    <div class="picture">
                        <!-- <img src="../../assets/images/03.png"> -->
                        <!-- <video id="video" #video width="640" height="480" autoplay></video> -->
                        <div style="width: 230px;">
                            <div style="margin:auto 0px;">
                                <div class="border_card">

                                </div>
                                <webcam [width]="230" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                    [videoOptions]="videoOptions" [imageQuality]="1"
                                    (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                                </webcam>

                            </div>
                        </div>
                        <div style="color: #fff;">วางหน้าบัตรให้ตรงในกรอบที่กำหนด</div>
                        <!-- <button id="snap" (click)="capture()">Snap Photo</button>
                        <canvas #canvas id="canvas" width="640" height="480"></canvas> -->
                        <!-- <div class="refresh">
                            <img src="../../assets/images/Icon material-refresh.png">
                        </div> -->
                        <div class="btn-capture" (click)="triggerSnapshot();">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-sm-12">
                <div class="display">
                    <div class="picture">
                        <div class="snapshot" *ngIf="webcamImage">
                            <img [src]="webcamImage.imageAsDataUrl" />
                        </div>
                        <img src="../../assets/images/itg-197.png" *ngIf="!webcamImage">
                    </div>
                    <span class="text-danger waning text-center">
                        โปรดตรวจเช็คความคมชัดของภาพ<br />
                        ก่อนทำการบันทึกที่ปุ่มด้านล่าง
                    </span>
                    <button class="btn btn-success btn-sm" *ngIf="webcamImage" (click)="save_idcard(modal)">
                        บันทึกภาพนี้
                    </button>
                </div>

            </div>
        </div>
    </div>

</ng-template>
<ng-template #take_photoEle let-modal class="">

    <div class="modal-body camera">
        <!-- <div class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="../../assets/images/Icon metro-cross.png">
        </div> -->
        <div class="row">
            <div class="col">
                <div class="phone">
                    <div class="mic"></div>
                    <div class="picture">
                        <!-- <img src="../../assets/images/03.png"> -->
                        <!-- <video id="video" #video width="640" height="480" autoplay></video> -->
                        <div>
                            <div style="margin:auto 0px;">

                                <webcam [width]="230" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                    [videoOptions]="videoOptions" [imageQuality]="1"
                                    (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                                </webcam>

                            </div>
                        </div>
                        <div style="color: #fff;" class="text-center">ให้ใบหน้าของคุณอยู่<br />ในกรอบที่กำหนด</div>
                        <!-- <button id="snap" (click)="capture()">Snap Photo</button>
                        <canvas #canvas id="canvas" width="640" height="480"></canvas> -->
                        <!-- <div class="refresh">
                            <img src="../../assets/images/Icon material-refresh.png">
                        </div> -->
                        <div class="btn-capture" (click)="triggerSnapshot();">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="display">
                    <div class="picture">
                        <div class="snapshot" *ngIf="webcamImage">
                            <img [src]="webcamImage.imageAsDataUrl" />
                        </div>
                        <img src="../../assets/images/itg-199.png" *ngIf="!webcamImage">
                    </div>

                    <span class="text-danger waning text-center">
                        โปรดตรวจเช็คความคมชัดของภาพ<br />
                        ก่อนทำการบันทึกที่ปุ่มด้านล่าง
                    </span>
                    <button class="btn btn-success btn-sm" *ngIf="webcamImage" (click)="save_people(modal)">
                        บันทึกภาพนี้
                    </button>
                </div>

            </div>
        </div>
    </div>

</ng-template>
<app-footer></app-footer>