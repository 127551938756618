<app-header2></app-header2>

<div id="body" class="content">
<div class="header-page">
  <div class="p-4 pl-5">
    <h1 class="container p-30">
      หลักสูตรทั้งหมด
    </h1>
  </div>
</div>

<div class="desktop mobile mb-3">
  <div class="container mt-5">
    <div class="row row-course-list">
      <div class="col-12">
        <div class="float-left py-3">
          <!-- <label class="mr-1">เลือกดู</label> -->
          <select class="px-2 py-1" style="border-radius: 25px; width: 50%;" [(ngModel)]="cSelect"
            (ngModelChange)="getRoundByCourse()">
            <option value="0">หลักสูตรทั้งหมด</option>
            <option [value]="c.cg_id" *ngFor="let c of _Courses">{{c.title}}</option>
          </select>
        </div>
        <div id="search-div" class="py-3">
          <i class="fas fa-search position-relative" style="left: 33px; top:2px"></i>
          <input class="py-1 pl-2 search" type="text" placeholder="ค้นหาหลักสูตร"
            [(ngModel)]="search" (ngModelChange)="searchRound()"
            style="padding-left: 40px!important;border-radius: 25px;">
        </div>
      </div>
      <div class="text-center h3 fw-bloder p-3" *ngIf="_TrainingRound.length < 1">ไม่พบหลักสูตร</div>
      <div class="col-sm-12 col-md-4 col-lg-3 mb-3" *ngFor="let tr of _TrainingRound">
        <div class="card card-course shadow-sm">
          <a [routerLink]="'/detail-courese/'+tr.cg_id" class="overflow-hidden">
            <img class="card-img-top zoom" src="https://images-na.ssl-images-amazon.com/images/I/71hrRnjQYxL._RI_.jpg"
              [src]="tr.icon==''?'https://images-na.ssl-images-amazon.com/images/I/71hrRnjQYxL._RI_.jpg':'https://www.lmsexpert.org/api/'+tr.icon"
              alt="">
          </a>
          <div class="card-body">
            <div class="row title-time">
              <div class="col-12 col-sm-12 col-md-12 col-lg-8 fs-round">
                <label class="title-round">{{tr.title_rt}}</label>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-4 fs-round">
                {{tr.duration|number:'1.0-2'}} ชม.
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                <a [routerLink]="'/detail-courese/'+tr.cg_id">
                  <h5 class="fw-bolder">{{tr.title_cg}}</h5>
                </a>
                <div>
                  <label style="font-size: 14px;" class="fw-bolder">ลงได้ถึง :
                    &nbsp;</label><span>{{api.get_text_date(tr.limit_date)}}</span>
                </div>
                <div>
                  <label style="font-size: 14px;" class="fw-bolder">เริ่ม : &nbsp;</label>
                  <span>{{api.get_text_date(tr.time_start)}}</span>
                </div>
                <div>
                  <label style="font-size: 14px;" class="fw-bolder">สิ้นสุด : &nbsp;</label>
                  <span>{{api.get_text_date(tr.time_end)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center my-5">
        <nav aria-label="Page navigation pagination-page">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link page-link-left" (click)="swapPage('neg')">
                <i class="fas fa-angle-left"></i>
                ย้อนกลับ
              </a>
            </li>
            <li class="page-item" *ngFor="let p of page" (click)="pickPage(p)"><label class="page-link">{{p}}</label>
            </li>
            <li class="page-item">
              <a class="page-link page-link-right" (click)="swapPage('pos')">
                ถัดไป
                <i class="fas fa-angle-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
</div>

<app-footer2></app-footer2>