<app-headerapprove [url]="url"></app-headerapprove>

<div class="content" style="height: 85vh;" *ngIf="_passcodeState == 2">
    <div class="m-3 d-flex justify-content-center">
        <div class="card p-3" style="max-width: 450px;">
            <div class="row">
                <div class="col-12 text-center">
                    <label class="fw-bolder">กรอกรหัสผ่าน</label>
                    <input type="text" class="form-control text-center" [(ngModel)]="passcode">
                    <label class="text-danger py-2"
                        *ngIf="_passcodeState == 2 && _passcodeStateStr != ''">{{_passcodeStateStr}}</label>
                </div>
                <div class="col-12 d-grid gap-2 pt-1">
                    <button class="btn text-white" style="background-color: #115ba5;"
                        (click)="confirmPass(passcode)">ยืนยัน</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-regisform container container-fluid-mb my-3 " *ngIf="_passcodeState == 1">
    <!-- <div class="row card-approve d-none d-sm-block"> -->
    <div class="row card-approve">
        <div class="col-12 col-12-md">
            <div class="card shadow-sm">
                <!-- <div class="pb-3">
                    <div (click)="exit()" class="position-absolute py-2 px-3 font-weight-bold text-white"
                        style="cursor: pointer;;box-shadow: inset 2px -2px 3px 0px #dcd4d485;right: 0px;width:max-content;background-color: #E15454;font-size: x-small;">
                        Exit
                        <i class="fas fa-external-link-alt"></i>
                    </div>
                </div> -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="border-bottom py-3 d-block d-md-none">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <label class="font-weight-bold mb-3 " style="color: #115ba5 ;">ลงทะเบียนอบรม
                                            AIMC</label>
                                        <br>
                                        <!-- <img class="rounded-circle" style="border: solid 5px #426480;" width="100px"
                      src="../../assets/images/logo.jpg" alt=""> -->
                                    </div>
                                    <div class="col-12 pt-3">
                                        <label class="font-weight-bold mt-2" style="color: #115ba5 ;">
                                            จำนวนผู้เข้าอบรม
                                            &nbsp;
                                            (
                                            <span>{{user_approved}}</span> / <span>{{max_user}}</span>
                                            )
                                            &nbsp;
                                            คน
                                        </label>
                                        <br>
                                        <label class="font-weight-bold" style="color: #115ba5 ;">
                                            <label>หลักสูตร</label>
                                            &nbsp;
                                            <span class="font-weight-light">{{course.cg_title}}</span>
                                        </label>
                                        <div>
                                            <label class="font-weight-bold" style="color: #115ba5 ;">
                                                ลงทะเบียน
                                            </label>
                                            &nbsp;
                                            <a href="https://www.lmsexpert.org/register-round/{{url}}"
                                                target="_blank">https://www.lmsexpert.org/register-round/{{url}}</a>

                                            <br />
                                            <button class="btn btn-success btn-sm text-white m-1" id="copy"
                                                style="font-size: x-small;border-radius: 15px;background-color: #198754;"
                                                (click)="onCopy('https://www.lmsexpert.org/register-round/'+url)">
                                                <i class="fas fa-link"></i>
                                                คัดลอกลิงค์
                                            </button>

                                            <button class="btn btn-sm text-white m-1"
                                                style="font-size: x-small;border-radius: 15px;background-color: #0a58ca;"
                                                (click)="export(url)">
                                                <i class="far fa-file-excel"></i>
                                                Export
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="border-bottom pb-4 d-none d-md-block" style="border-color: #115ba5 !important;">
                                <div class="row">
                                    <div class="inline-block pl-5">
                                        <!-- <img class="rounded-circle p-2" style="border: solid 5px #426480;" width="150px"
                      src="../../assets/images/logo.jpg" alt=""> -->
                                    </div>
                                    <div class="inline-block" style="min-width: 350px;">

                                        <h2 class=" font-weight-bold text-center" style="color: #115ba5 ;">ลงทะเบียนอบรม
                                            AIMC (Preapprove)</h2>
                                        <div class="h5 font-weight-bold" style="color: #115ba5 ;">
                                            <label style="margin:0px">หลักสูตร</label>
                                            &nbsp;
                                            <span class="font-weight-light">{{course.cg_title}}</span>
                                        </div>

                                        <div class="h5 font-weight-bold" style="color: #115ba5 ;">
                                            <label style="margin:0px">จำนวนผู้เข้าอบรม</label>
                                            &nbsp;
                                            <span class="font-weight-light">(<span>{{user_approved}}</span> /
                                                <span>{{max_user}}</span>)</span>
                                        </div>


                                        <div class="h5">
                                            <label class=" font-weight-bold" style="color: #115ba5 ;">
                                                ลงทะเบียน
                                            </label>
                                            &nbsp;
                                            <a target="_blank"
                                                href="https://www.lmsexpert.org/register-preapprove/{{url}}">https://www.lmsexpert.org/register-preapprove/{{url}}</a>
                                            <!-- <input class="mb-2" type="text" value="https://www.lmsexpert.org/api/service/register/{{url}}"
                        readonly style="min-width: 400px;">
                      <br> -->
                                            <button class="btn btn-success btn-sm text-white m-1" id="copy"
                                                style="border-radius: 15px;background-color: #198754;"
                                                (click)="onCopy('https://www.lmsexpert.org/register-preapprove/'+url)">
                                                <i class="fas fa-link"></i>
                                                คัดลอกลิงค์
                                            </button>
                                            <div style="position: absolute;right: 0; margin-top: -43px;">
                                                <div class="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button"
                                                        class="btn btn-info dropdown-toggle btn-rounded"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        นำเข้า
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <button class="dropdown-item" href (click)="download()">Download
                                                            Template</button>
                                                        <button class="dropdown-item" href
                                                            (click)="import(url)">นำเข้า</button>
                                                    </div>
                                                </div>
                                                <button class="btn text-white m-1 btn-success btn-rounded"
                                                    (click)="export(url)">
                                                    <i class="far fa-file-excel"></i>
                                                    ส่งออก
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6 text-left py-1">
                                    <select style="max-width: 149px;
                  height: 35px;
                  background-color: #fff;
                  text-align: right;" class="form-control" [(ngModel)]="page" (change)="changePage(page)">
                                        <option value="0">ทั้งหมด</option>
                                        <option *ngFor="let page of data_page" [value]="page">หน้า {{page}}</option>
                                    </select>
                                </div>
                                <div class="col-6 text-right py-1">
                                    <label class="font-weight-bold" style="padding-top: 4px;">ค้นหาผู้เข้าอบรม</label>
                                    &nbsp;
                                    <!-- <input type="text" ng-model="search_user" ng-change="delay(searchUser, 500)"> -->
                                    <input type="text" class="form-control" style="float: right;
                  width: 171px;
                  height: 31px;" [(ngModel)]="search" (ngModelChange)="findUser(search)">
                                </div>
                                <div class="col-12 col-12-md">
                                    <div class=" rounded user-table">
                                        <table class="table table-sm">
                                            <thead>
                                                <th>#</th>
                                                <th>รหัสพนักงาน</th>
                                                <th>ID CARD</th>
                                                <th>ชื่อ</th>
                                                <th>เบอร์โทร</th>
                                                <th>Email</th>
                                                <th>ต่ออายุ</th>
                                                <th class="text-center">สถานะ</th>
                                                <th class="text-right">
                                                    <span style="font-size: smaller;">Action</span>
                                                    <br>
                                                    <input type="checkbox" class="_ischeck_all checkbox-c"
                                                        style="width: 20px;" [(ngModel)]="check_all"
                                                        (change)="onCheckAll()">
                                                </th>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let user of data.user;let i = index">
                                                    <td>{{((page-1)*10)+(i + 1)}}</td>
                                                    <td>{{user.user_ref}}</td>
                                                    <td>{{user.cid}}</td>
                                                    <td>{{user.name_title}}{{user.first_name}} {{user.last_name}}</td>
                                                    <td>{{user.number_phone}}</td>
                                                    <td>{{user.user_email}}</td>
                                                    <td>
                                                        <span *ngIf="user.renew=='0'">-</span>
                                                        <span *ngIf="user.renew=='1'">ตนเอง</span>
                                                        <span *ngIf="user.renew=='2'">AIMC</span>
                                                        <span *ngIf="user.renew=='3'">AIA</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span class="badge bg-secondary"
                                                            *ngIf="user.status - 0 == 0">wait</span>
                                                        <span *ngIf="user.status - 0 == 1"
                                                            class="badge bg-success">Approved</span>
                                                        <span *ngIf="user.status - 0 == 2"
                                                            class="badge bg-danger">Reject</span>
                                                    </td>
                                                    <td class="text-right">
                                                        <input type="checkbox" class="_ischeck checkbox-c"
                                                            style="width: 20px;" [checked]="user._ischeck"
                                                            (click)="onCheck(user._ischeck,i)">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-12 text-right pt-3">
                                    <!-- <label class="font-weight-bold">
                                        จำนวนที่เลือก
                                        &nbsp;
                                        <span>{{user_select}}</span> คน
                                        <span *ngIf="user_select>(max_user - user_approved)" style="color: #dc3545;">(
                                            เกิน
                                            {{user_select-(max_user - user_approved)}}
                                            คน )</span>
                                    </label>
                                    <br> -->
                                    <!-- <button class="btn btn-success btn-sm text-white mr-1" style="border-radius: 15px;"
                                        (click)="approve('Approve')"
                                        ng-disabled="user_select>(max_user - user_approved)">
                                        <i class="fas fa-user-check"></i>
                                        APPROVE
                                    </button> -->
                                    <button class="btn btn-danger btn-sm text-white ml-1" style="border-radius: 15px;"
                                        (click)="remove_user()">
                                        <i class="fa fa-trash"></i>
                                        ลบออก
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row m-2 d-block d-sm-none">
      <div class="col-12">
          <div class="text-center p-2" style="margin-top: 25vh;">
              <img src="https://www.lmsexpert.org/assets/images/RoundPhone.png" width="200">
              <label class="font-weight-bold mt-2" style="color: #115ba5 ;">โปรดกลับด้านหน้าจอโทรศัพท์เพื่อดำเนินการต่อ.</label>
          </div>
      </div>
  </div> -->
</div>
<ng-template #import_modal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">นำเข้า</h5>
        <div class="pull-right">
            <input style="display: none" #fileSelect type="file" accept=".xlsx" (change)="selectFiles($event)">
            <button type="button" class="btn btn-success btn-sm btn-rounded"
                (click)="fileSelect.click()">เพิ่มไฟล์</button>
        </div>
        <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="">
        <div style="width: 100%;">
            <div [ngStyle]="{'width': progressInfos.value+'%'}" style="height: 3px; background-color: #51be09;"> </div>
        </div>

        <table class="table table-hover" style="margin-bottom: 0px;">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">ID Card</th>
                    <th scope="col">รหัสพนักงาน</th>
                    <th scope="col">ชื่อ</th>
                    <th>เบอร์โทร</th>
                    <th>Email</th>
                    <th>ลบ</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of import_data;let i = index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.cid}}</td>
                    <td>{{item.user_ref}}</td>
                    <td>{{item.name_title}} {{item.first_name}} {{item.last_name}}</td>
                    <td>{{item.user_email}}</td>
                    <td>{{item.number_phone}}</td>
                    <td style="padding: 4px;">
                        <button (click)="remove(i)" class="btn btn-danger btn-sm">ลบ</button>
                    </td>
                </tr>

            </tbody>
        </table>
        <div class="modal-body">

            <div class="text-right">
                <button type="button" class="btn btn-success btn-sm btn-rounded" [disabled]="import_data.length==0"
                    (click)="import_data_f()">นำเข้า</button>
                <button type="button" class="btn btn-dark btn-sm btn-rounded m-l-sm" style="margin-left: 10px;"
                    (click)="modal.close()">ปิดออก</button>
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm btn-rounded"
            (click)="modal.close('Close click')">ปิดออก</button>
    </div> -->
</ng-template>
<app-footer2></app-footer2>