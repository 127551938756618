import { InhouseCoursesComponent } from './inhouse-courses/inhouse-courses.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, rountingcomponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SetupComponent } from './setup/setup.component';
import { LearningComponent } from './learning/learning.component';
import { NgbActiveModal, NgbAlertModule, NgbModalConfig, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { TopicComponent } from './topic/topic.component';
import { WebcamModule } from 'ngx-webcam';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { KycComponent } from './kyc/kyc.component';
import { ChooseTopicComponent } from './choose-topic/choose-topic.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgotComponent } from './forgot/forgot.component';
import { NewPassComponent } from './new-pass/new-pass.component';
import { Header2Component } from './header2/header2.component';
import { Home2Component } from './home2/home2.component';
import { Footer2Component } from './footer2/footer2.component';
import { AllCoureseComponent } from './all-courese/all-courese.component';
import { DetailCoureseComponent } from './detail-courese/detail-courese.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { QAComponent } from './qa/qa.component';
import { RegisterRoundComponent } from './register-round/register-round.component';
import { ApproveComponent } from './approve/approve.component';
import { ManualComponent } from './manual/manual.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PreapproveComponent } from './preapprove/preapprove.component';
import { RegisterPreapproveComponent } from './register-preapprove/register-preapprove.component';
import { HeaderapproveComponent } from './headerapprove/headerapprove.component';
// import { NgxFaceApiJsModule } from 'ngx-face-api-js';
@NgModule({
  declarations: [
    AppComponent,
    rountingcomponents,
    HeaderComponent,
    FooterComponent,
    SetupComponent,
    LearningComponent,
    ComingsoonComponent,
    TopicComponent,
    KycComponent,
    ChooseTopicComponent,
    ForgotComponent,
    NewPassComponent,
    Header2Component,
    Home2Component,
    Footer2Component,
    AllCoureseComponent,
    DetailCoureseComponent,
    AboutUsComponent,
    ContactUsComponent,
    QAComponent,
    InhouseCoursesComponent,
    RegisterRoundComponent,
    ApproveComponent,
    ManualComponent,
    PreapproveComponent,
    RegisterPreapproveComponent,
    HeaderapproveComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgbModule,
    NgbPaginationModule, NgbAlertModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    WebcamModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    // NgxFaceApiJsModule.forRoot({
    //   modelsUrl: 'assets/weights',
    // }),
  ],
  providers: [NgbModalConfig,NgbActiveModal],
  bootstrap: [AppComponent],
  exports: [BsDropdownModule, TooltipModule, ModalModule]
})
export class AppModule { }
